import React, { useState } from "react";

import mover from '../../../../../images/iconRed-01.png';
import dehumidifier from '../../../../../images/iconRed-02.png';
import scrubber from '../../../../../images/iconRed-03.png';

//Hover Grey
// import moverHover from '../../../../../images/iconGrey-01.png';
// import dehumidifierHover from '../../../../../images/iconGrey-02.png';
// import scrubberHover from '../../../../../images/iconGrey-03.png';

// //Hover White
import moverHover from '../../../../../images/iconWhite-01.png';
import dehumidifierHover from '../../../../../images/iconWhite-02.png';
import scrubberHover from '../../../../../images/iconWhite-03.png';


export default function Equiptment() {
  const [airMover, setAirmover] = useState(true);
  const [dehu, setDehu] = useState(true);
  const [airScrubber, setairScrubber] = useState(true);

  const [hover, setHover] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  
  return (
    <section className="equipment__section">
      <div className="equipment__div-Container">
        <h2> Pro Dri Professional Restoration Equipment</h2>
        <p className="equipment__div-subtitle">
          Air Movers, Dehumidifiers, Air Scrubbers and everything you need for
          your next restoration job.
        </p>
        <div className="equipment__div-iconContainer">
          <div className="equipment__div-equpitmentCont">
            <div className="equipment_-div-iconCont"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {!hover ? (
                <img
                  src={mover}
                  alt="Air Mover"
                  className="icon"
        
                />
              ) : (
                <img
                  src={moverHover}
                  alt="Air Mover"
                  className="iconHover"
                  onClick={() => setAirmover(!airMover)}
                />
              )}
            </div>
            <h3 className="equipment__div-h3">Air Movers</h3>

            {airMover ? (
              <p className="equipment__div-p">
                Air movers push air across the wet surfaces and promote
                evaporation. Get things dried out fast!
              </p>
            ) : (
              <p className="equipment__div-p">
                Air movers push air across the wet surfaces and promote
                evaporation. Pro-Dri commercial air movers are specially
                designed to deliver fast and efficient drying to floors, walls
                and affected materials. Pro-Dri air movers come in three
                standard sizes. The Pro-Dri Mini Air Mover is perfect for
                delivery high air flow in tight spaces. Our best selling air
                mover is the Pro-Dri Mid-Size Air Mover. It is the work-horse of
                the industry and delivers powerful drying and built to last
                durability. Finally the Pro-Dri Large Air mover, at 3/4 HP this
                fan packs a huge punch and is best for jobs that need dried
                fast!
              </p>
            )}

            {!airMover ? (
              <span className="equipment__button" id="dealer"  onClick={() => setAirmover(!airMover)}>Collapse</span>
            ) : (
              <span className="equipment__button" onClick={() => setAirmover(!airMover)}>Learn More</span>
            )}
          </div>

          <div className="equipment__div-equpitmentCont">
            <div className="equipment_-div-iconCont"
              onMouseEnter={() => setHover1(true)}
              onMouseLeave={() => setHover1(false)}
            >
              {!hover1 ? (
                <img
                  src={dehumidifier}
                  alt="Dehumidifier"
                  className="icon"
                />
              ) : (
                <img
                  src={dehumidifierHover}
                  alt="Dehumidifier"
                  className="iconHover"
                  onClick={() => setDehu(!dehu)}
                />
              )}
            </div>
            <h3 className="equipment__div-h3">Dehumidifier</h3>

            {dehu ? (
              <p className="equipment__div-p">
                Dehumidifiers lower the humidity of  an area by converting water vapor into a liquid and expelling it. 
              </p>
            ) : (
              <p className="equipment__div-p">
               Dehumidifiers effectively lower the humidity of  an area by converting water vapor into a liquid and expelling it from the room. This means faster drying times and as a result, a lower chance for mold growth! Pro-Dri dehumidifiers combine efficient performance with heavy duty construction to provide drying solutions that empower your business. Our most popular Pro-Dri Dehumidifier pumps an impressive amount of water from the air and is the preferred size for insurance claims.
              </p>
            )}

            {!dehu ? (
              <span className="equipment__button" onClick={() => setDehu(!dehu)}>Collapse</span>
            ) : (
              <span className="equipment__button"onClick={() => setDehu(!dehu)}>Learn More</span>
            )}
          </div>

          <div className="equipment__div-equpitmentCont">
            <div className="equipment_-div-iconCont"
              onMouseEnter={() => setHover2(true)}
              onMouseLeave={() => setHover2(false)}
            >
              {!hover2 ? (
                <img
                  src={scrubber}
                  alt="Air Scrubber"
                  className="icon"
                />
              ) : (
                <img
                  src={scrubberHover}
                  alt="Air Scrubber"
                  className="iconHover"
                  onClick={() => setairScrubber(!airScrubber)}
                />
              )}
            </div>
            <h3 className="equipment__div-h3">Air Scrubbers</h3>

            {airScrubber ? (
              <p className="equipment__div-p">
                Air Scrubbers are at the defensive forefront, filtering the air on your job and ensuring you and your techs stay safe.
              </p>
            ) : (
              <p className="equipment__div-p">
                 Air Scrubbers are at the defensive forefront, filtering the air on your job and ensuring you and your techs stay safe. Each Pro-Dri air scrubber contains a three-stage filtration system. The first stage aids in removing fine dust particles from the air. Stage two is an impressive Merv 8 filter to aid in mold filtration. The Final step is a bulletproof HEPA filter. So no matter how dirty the job you can put your trust in the Pro-Dri heavy duty HEPA air scrubber.
              </p>
            )}

            {!airScrubber ? (
              <span className="equipment__button" onClick={() => setairScrubber(!airScrubber)}>
                Collapse
              </span>
            ) : (
              <span className="equipment__button" onClick={() => setairScrubber(!airScrubber)}>
                Learn More
              </span>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
