import React, { useState } from "react";
import ProductsChild from "./ProductsChild";

import dehumidifier from "../../../../../images/products/dehumidifier_PD.png";
import airMover from "../../../../../images/products/airMover_PD.png";
import airMoverMini from "../../../../../images/products/airMoverMini_PD.png";
import airScrubber from "../../../../../images/products/airScrubber_PD.png";
import hepaAirScrubber from "../../../../../images/products/hepaAirScrubber_PD.jpg";
import axialAirMover from "../../../../../images/products/axialAirMover_PD.jpg";
import lowProfile from "../../../../../images/products/lowProfile_PD.jpg";
import axialDownDraft from "../../../../../images/products/axialDownDraft_PD.png";
import verticalDehumidifier from "../../../../../images/products/verticalDehumidifier_PD.jpg";
import horizontalDehumidifier from "../../../../../images/products/horizontalDehumidifier_PD.jpg";

import fan from "../../../../../images/pinWheel.png";
import droplet from "../../../../../images/droplet.png";
import atom from "../../../../../images/atom.png";

export default function ProductParent() {
  const products = [
    {
      name: "PRO-DRI AIR MOVER",
      iconUrl: fan,
      airMover: true,
      airScrubber: null,
      deHumidifier: null,
      tagPrefix: "AIRFLOW: ",
      tagNumbers: 900,
      tagSuffix: " CFM",
      code: "WTRDRY",
      imgUrl: airMover,
      bannerText: "Get the highest air flow possible with less than 3 amps ",
      description:
        "The Pro-Dri Mid Air Mover pushes an impressive 3829 FPM while pulling a low 2.9 amps. This allows you to utilize the 2 GFCI outlets for easy daisy-chaining. Ergonomic design allows you to stack your air movers for easy and efficient storage. It’s no wonder this powerful fan dominates the drying sphere and is the best selling air mover on the market.",
      specifications: [
        "ETL Certified",
        "Motor: 1/5 Horsepower, 3-Speed",
        "Power: 115 V 60 HZ",
        "Max Draw: 2.9 amps",
        "Weight: 26 Pounds",
        "Cord: 25 feet",
        "Outlet: GFCI Power Outlet",
        "Dimensions: 18″ x 18″ x 18.5″",
        "Stackable",
      ],
    },
    {
      name: "PRO_DRI MINI AIR MOVER",
      iconUrl: fan,
      airMover: true,
      airScrubber: null,
      deHumidifier: null,
      tagPrefix: "AIRFLOW: ",
      tagNumbers: 789,
      tagSuffix: " CFM",
      code: "WTRDRY",
      imgUrl: airMoverMini,
      bannerText: "Dynamite air flow comes in a small package ",
      description:
        "The Pro-Dri Mini Air Mover is perfect for drying small, hard to reach spaces like cabinets and behind toilets. It boasts air flow up to 778 CFM. The 3 speed switch combined with directional air flow makes this little fan one of the most versatile air movers on the market. The standard GFCI outlet gives you the option of daisy-chaining several of these little giants together. Their size and stackability means easy storage and a smaller footprint in your van or trailer. Don&#8217t leave any nook or cranny un-dried with the Mini Air Mover from Pro-Dri.",
      specifications: [
        "ETL Certified",
        "Motor: 1/5 Horsepower, 3-Speed",
        "Power: 115 V 60 HZ",
        "Max Draw: 1.9 amps",
        "Weight: 7.7 Pounds",
        "Cord: 10 feet",
        "Outlet: GFCI Power Outlet",
        "Dimensions: 10.7″ x 12.4″ x 13.3″",
        "Stackable",
      ],
    },
    {
      name: "PRO-DRI HEPA AIR SCRUBBER",
      iconUrl: atom,
      airMover: null,
      airScrubber: true,
      deHumidifier: null,
      tagPrefix: "",
      tagNumbers: 99.97,
      tagSuffix: " % OF PARTICLES CAPTURED",
      code: "WTRDRY",
      imgUrl: hepaAirScrubber,
      bannerText: "HEPA's 3 Stages air purification process ",
      description:
        "The Pro-Dri HEPA Air Scrubber can deliver up to 500 CFM of filtered airflow while pulling a low 1.9 amps. Enjoy the versatility of a variable speed selector which allows you to choose the exact amount of desired airflow to perfectly match the needs of any restoration job. Every Pro-Dri HEPA Air Scrubber comes with a change filter light to take the guess work out of filter replacement. The easy open latch makes it a piece of cake to change filters in a flash.",
      specifications: [
        "Power: 115V 60HZ",
        "Air Flow: 300-500 CFM",
        "Max draw: 1.9 AMPS",
        "Weight: 43 LBS",
        "Body Construction: Roto-molded Polyethylene",
        "Stackable",
        "Dimensions: 24.8”  x 15.7”  x 24” ",
        "True HEPA 3-Stage System",
        "ETL Certified",
      ],
    },
    {
      name: "PRO-DRI AXIAL AIR MOVER",
      iconUrl: fan,
      airMover: true,
      airScrubber: null,
      deHumidifier: null,
      tagPrefix: "AIRFLOW: ",
      tagNumbers: 3300,
      tagSuffix: " CFM",
      code: "WTRDRY",
      imgUrl: axialAirMover,
      bannerText: "A revolutionary way to move air ",
      description:
        "The Pro-Dri Axial Air Mover is a revolutionary fan that provides super lightweight and long-life operation, matienance-fre performance. I comes equipt with a high quality water proof switch and dual outlets for daisy-chaining multiple units using regular power. Its easy wrap around cod design keeps everything neat and out of the way on the job. It is easily stacked and works in almost any situation.",
      specifications: [
        "Power: 115V 60HZ",
        "Motor: 1/4 HP",
        "AMPS: 1.3 -2.6",
        "Weight: 30 LBS",
        "Body Construction: Roto-molded Polyethylene",
        "Dimensions: 22.5” x 20” x 13”",
        "ETL Certified",
        "Stackable",
      ],
    },
    {
        name: "PRO-DRI DEHUMIDIFIER",
        iconUrl: droplet,
        airMover: null,
        airScrubber: null,
        deHumidifier: true,
        tagPrefix: "",
        tagNumbers: 125,
        tagSuffix: " PINTS REMOVED PER DAY",
        code: "WTRDHM",
        imgUrl: verticalDehumidifier,
        bannerText: "Newest Model for the Restoration Industry ",
        description: "The Pro-Dri Dehumidifier is your go-to for water damage restoration. Combining professional performance with a sleek, high-tech design, this dehumidifier was designed for the serious restoration professional. The Dehumidifier can pump out over 125 pints at AHAM. Featuring a rugged, roto-molded PE housing, the PRO-DRI Dehumidifier is dent-resistant, easy to clean and offers exceptional durability. No matter how big your next water damage job is you can trust that the Pro-Dri Dehumidifier will deliver extraordinary results.",
        specifications: [
          "Power: 115v 60v",
          "Current: 7.5amp",
          "Water Removal: 125PPD/ 76PPD @ AHAM",
          "Refrigerant: R410A",
          "Weight: 99LBS",
          "Dimensions: 25” x 22.5 x 37”",
          "Body Construction: Roto-molded Polyethylene",
          "CE Certified",
        ],
      },
    {
      name: "PRO-DRI LOW PROFILE",
      iconUrl: fan,
      airMover: true,
      airScrubber: null,
      deHumidifier: null,
      tagPrefix: "AIRFLOW: ",
      tagNumbers: 2220,
      tagSuffix: " CFM",
      code: "WTRDRY",
      imgUrl: lowProfile,
      bannerText: "A great way to save space ",
      description:
        "The Pro-Dri Low Profile Air Mover has a specifically designed motor for low amperage and water proof function. The Roto Mold Housing is durable and long-lasting. The small size allows over 5 units to be stacked and the dual outlet is available for daiy chaining over 5 units. This is a great option for those looking for something a little lower.",
      specifications: [
        "Power: 115v 60hz",
        "Output: 190 W",
        "Motor: 1/4 HP",
        "Weight: 26.5 LBS",
        "Amps: 2.0",
        "Dimensions: 22.5” x 17” x 8.5”",
        "Body Construction: Roto-molded Housing",
        "Cord: 25 feet",
        "Stackable",
      ],
    },
    {
      name: "PRO-DRI AXIAL DOWN DRAFT",
      iconUrl: fan,
      airMover: true,
      airScrubber: null,
      deHumidifier: null,
      tagPrefix: "AIRFLOW: ",
      tagNumbers: 3500,
      tagSuffix: " CFM",
      code: "WTRDRY",
      imgUrl: axialDownDraft,
      bannerText: "Perfect for any direction ",
      description:
        "The Pro-Dri Axial Flow Fan is perfect for many situations due to its unique design. It is the ideal Air Mover because can be used in various positions and in tough situations. It is manufactured to work 24/7 without any problems. The small size and unique shape allows it to be stackable over 10 units in order to save storage space. The water proof switch and dual power outlet for daisy chaining allow this product to work well. The durable cast iron fan blade is made for better air volume.",
      specifications: [
        "Power: 115V 60hz",
        "Motor: 1/4 HP",
        "Amps: 1.7",
        "Air Speed: 10m/s",
        "Weight: 30.8 LBS",
        "Dimensions: 21.3” x 11.8” x 22.8”",
        "Stackable",
        "ETL Certified",
      ],
    },


    {
      name: "PRO-DRI COMPACT DEHUMIDIFIER",
      iconUrl: droplet,
      airMover: null,
      airScrubber: null,
      deHumidifier: true,
      tagPrefix: "",
      tagNumbers: 80,
      tagSuffix: " PINTS REMOVED PER DAY",
      code: "WTRDHM",
      imgUrl: horizontalDehumidifier,
      bannerText: "Smaller Size for Stacking ",
      description: "The Pro-Dri Compact Dehumidifier can pump out over 85 pints of moisture at AHAM. The easy to retract handle high quality weels are great for transport. Featuring a robust water pope connector this dehumidifier doesn't leak. Its smaller size is also great for stacking and can stack up to 3 units.",
      specifications: [
        "Power: 115V 60HZ",
        "Refrigerant: R410A",
        "Current: 1.7amp",
        "Water Removal: 80PPD/ 85PPD @ AHAM",
        "Weight: 77LBS",
        "Body Construction: Roto-molded Polyethylene",
        "Dimensions: 22” x 14.5” x 17.7”",
        "CE Certified",
      ],
    },
  ];

  const previousProducts = [
    {
      name: "PRO-DRI DEHUMIDIFIER",
      iconUrl: droplet,
      airMover: null,
      airScrubber: null,
      deHumidifier: true,
      tagPrefix: "",
      tagNumbers: 125,
      tagSuffix: " PINTS REMOVED PER DAY",
      code: "WTRDHM",
      imgUrl: dehumidifier,
      bannerText: "Professional dehumidifying and superior design ",
      description:
        "The Pro-Dri Dehumidifier is your go-to for water damage restoration. Combining professional performance with a sleek, high-tech design, this dehumidifier was designed for the serious restoration professional. The Dehumidifier can pump out over 147 pints of moisture per day and 77 pints at AHAM. Featuring a rugged, roto-molded PE housing, the PRO-DRI Dehumidifier is dent-resistant, easy to clean and offers exceptional durability. No matter how big your next water damage job is you can trust that the Pro-Dri Dehumidifier will deliver extraordinary results.",
      specifications: [
        "Power: 115V 60HZ",
        "Water Removal: 125PPD/ 76PPD @ AHAM",
        "Refrigerant: R410A",
        "Air Flow: 410 CFM",
        "Max draw: 7.3 AMPS",
        "Weight: 85LBS",
        "Body Construction: Roto-molded Polyethylene",
        "Dimensions: 22” x 21” x 34”",
        "Operating range: 33º-100º",
        "ETL Certified",
        "Power Cord: 25’",
        "Drain Hose: 20′",
      ],
    },
    {
      name: "PRO-DRI HEPA AIRSCRUBBER",
      iconUrl: atom,
      airMover: null,
      airScrubber: true,
      deHumidifier: null,
      tagPrefix: "",
      tagNumbers: 99.97,
      tagSuffix: " % OF PARTICLES CAPTURED",
      code: "WTRNAFAN",
      imgUrl: airScrubber,
      bannerText: "3 Stages of advanced Air Purification ",
      description:
        "The Pro-Dri HEPA Air Scrubber can deliver up to 800 CFM of filtered airflow while pulling a low 2 amps. Enjoy the versatility of a variable speed selector which allows you to choose the exact amount of desired airflow to perfectly match the needs of any restoration job. Completing one air change in a 7,500 cubic foot room approximately every 15 minutes, the HEPA Air Scrubber from Pro-Dri meets the standard of four changes per hour. Utilize the GFCI outlet with a 12 amp breaker for maximizing your power sharing in a room. The 3-stage filtering process ensures the maximum removal of hazardous particles from your work environment. Every Pro-Dri HEPA Air Scrubber comes with a change filter light to take the guess work out of filter replacement.",
      specifications: [
        "Power: 115V 60HZ",
        "Air Flow: 250-800 CFM",
        "Max draw: 2.0 AMPS",
        "Weight: 35LBS",
        "Body Construction: Roto-molded Polyethylene",
        "Ductable",
        "Dimensions: 21”  x 21.5”  x 13” ",
        "True HEPA 3-Stage System",
        "ETL Certified",
        "Power Cord: 25’",
      ],
    },
  ];

  return (
    <section className="products__section">
      <a id="products"> </a>
      <div className="products__div-Cont">
        <h2 className="products__h2">
          <span className="products__span-Dash">— </span> Pro-Dri Product Line
          <span className="products__span-Dash"> —</span>
        </h2>
        <div>
          <div>
            {products.map((products, i) => (
              <ProductsChild key={i} {...products} />
            ))}
          </div>
        </div>

        <h3 className="products__h3">
          <span className="products__span-Dash">— </span> Previous Models
          <span className="products__span-Dash"> — </span>
        </h3>

        <div>
          {previousProducts.map((previousProducts, i) => (
            <ProductsChild key={i} {...previousProducts} />
          ))}
        </div>
      </div>
    </section>
  );
}
