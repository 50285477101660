import React from "react";

import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";

export default function Reviews() {
  const slides = [
    {
      review:
        "You won’t find a better deal for your restoration equipment! All of our Pro-Dri dehu’s and fans have exceeded our expectations! You really can’t go wrong with Pro-Dri!",
      reviewer: "Sam J. T.",
      occupation: "CEO",
    },
    {
      review:
        "Love the equipment. Couldn’t ask for a better starter pack for our business. Thanks guys, we’ll be back for more!",
      reviewer: "Allen C.",
      occupation: "CEO",
    },
    {
      review:
        "We have used the same brand of air mover for the last decade, honestly we didn’t know there was anything else out there! We got our first pack of Pro Dri air movers last spring, they have been awesome for us! I wish I had known about this company before!",
      reviewer: "Ryan",
      occupation: "Owner",
    },
  ];
  return (
    <section className="reviews__section" id="reviews">
      <div className="reviews__div-Container">
        <h2 className="reviews__h2">
          <span className="reviews__span-Dash">— </span> Client Testimonials
          <span className="reviews__span-Dash">— </span>
        </h2>

        <Slider autoplay={3000} className="slider-wrapper">
          {slides.map((item, index) => (
            <div key={index} className="slider-content">
              <div className="inner">
                <p>{item.review}</p>
              </div>
              <section>
                <span>
                  Posted by <strong>{item.reviewer}</strong>
                </span>
              </section>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
