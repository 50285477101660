import React from 'react';
import logo from './logo.svg';
import './App.scss';

import Home from "./ui/views/home/Home"
import Footer from "./ui/static/footer/Footer";
import Header from './ui/static/header/Header';

function App() {
  return (
    <>
    <Header/>
    <Home/>
    <Footer/>
    </>
  );
}

export default App;
