import React from "react";
import { Helmet } from "react-helmet";

import Banner from "./childComp/banner/Banner";
import Equipment from "./childComp/equipment/Equipment";
import Reviews from "./childComp/reviews/Reviews";
import ProductsParent from "./childComp/products/ProductsParent";
import Dealer from "./childComp/dealer/Dealer";
import Map from "./childComp/map/Map";

function Home() {
  return (
    <div>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          Pro-Dri Restoration Equipment - Restoration Equipment for Water, Fire
          and Mold Damage
        </title>
        <meta
          name="description"
          content="#1 Brand of Air Movers, Dehumidifiers and Air Scrubbers for your Restoration Business. Professional restoration equipment. Get a Faster ROI with Pro-Dri!"
        />
        <meta
          name="keywords"
          content="Air Movers, Dehumidifiers, Air Scrubbers, Water Damage, Repair, Restoration Equipment, Water Cleanup"
        />
      </Helmet>

      <Banner />
      <Equipment />
      <Dealer />
      <ProductsParent/>
      <Reviews />

      <Map />
    </div>
  );
}

export default Home;
