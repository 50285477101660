import React from "react";

export default function Dealer() {
  return (
    <section className="dealer__section">
      <a id="dealer"> </a>
      <div className="dealer__div-Container">
        <h2 className="dealer__h2">Your #1 Choice For Restoration Equipment</h2>
        <p className="dealer__p">
          If your restoration company is just starting out, we offer incredible
          deals on starter restoration packages. Prices start at under $5,000.
        </p>
        <a href="#map" className="dealer__a-button">
          Find a Dealer
        </a>
      </div>
    </section>
  );
}
