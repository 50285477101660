import React from "react";

function Nav({ toggle, toggleNav }) {
  return (
    <nav className={`nav ${toggle ? "open" : ""}`}>
      <ul className="nav__list">
        <li className="nav__item">
          <a
            href="#home"
            className="nav__link"
            onClick={toggleNav}          >
            Home
          </a>
        </li>

        <li className="nav__item">
          <a
            href="#products"
            className="nav__link"
          
            onClick={toggleNav}
          >
            Products
          </a>
        </li>

        <li className="nav__item">
          <a
            href="#map"
            className="nav__link"
            
            onClick={toggleNav}
          >
            Find a Dealer
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
