import React from "react";
import map from "../../../../../images/map.PNG";

export default function Map() {
  return (
    <section className="map__section">
      <a id="map"> </a>
      <div className="map__div-Container">
        <h2 className="map__h2">Authorized Dealers</h2>
        <div className="map__div-locationsCont">
          <div className="map__div-locationsList">
            <p className="map__p">
              <span className="map__span-header">
                Flood &amp; Fire Solutions Alpine
              </span>
              <br />
              #18 McCoy Creek Rd<br></br>
              Alpine Wy 83128<br></br>
              (307) 215-9741
            </p>

            <p className="map__p">
              <span className="map__span-header">Dry It Yourself Center</span>
              <br />
              568 W 19th Street<br></br>
              Idaho Falls, ID 83402<br></br>
              (208) 502-0201
            </p>

            <p className="map__p">
              <span className="map__span-header">
                Flood &amp; Fire Solutions
              </span>
              <br />
              570 W 19th Street<br></br>
              Idaho Falls, ID 83402<br></br>
              (208) 521-9741
            </p>

            <p className="map__p">
              <span className="map__span-header">
                Flood &amp; Fire Solutions Jackson
              </span>
              <br />
              970 W Broadway #363<br></br>
              Jackson, WY 83001<br></br>
              (307) 215-9741
            </p>
          </div>
          <div className="map__div-locationsMap"> 
              <img src={map} alt="map of distributors" className="map_img-map"/> 
              </div>

          {/* <iframe
            src="https://www.google.com/maps/d/u/0/embed?mid=11ky0OZauPQTHuaH_C6_uu_PAVKid7bXT"
          ></iframe> */}
        </div>
      </div>
    </section>
  );
}
