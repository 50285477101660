import React, { useState } from "react";
import Slide from "react-reveal/Slide";
import Tada from "react-reveal/Tada";

import tools from "../../../../../images/tools.png";
import checkMark from "../../../../../images/checkMark.png";
import barChart from "../../../../../images/barChart.png";

import logo from "../../../../../images/logo.png";

export default function Banner() {
  const [hover, setHover] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover4, setHover4] = useState(false);

  return (
    <section className="banner__section">
      <a id="home"> </a>
      <div className="banner__div-Container">
        <div className="banner__div-photoColumn" onMouseEnter={() => setHover4(!hover4)}>
          <div className="banner__div-logo">
            {/* <h1 className="banner__h1">PROFESSIONAL EQUIPMENT</h1> */}
            <img src={logo} alt="company name" className="banner__img-logo" />
          </div>
          <Slide left delay={1500}>
          <Slide left spy={hover4}>
            <div className="banner__div-colorBox1" />
          </Slide>
          </Slide>
          <div className="banner__div-subtitle">
            <h1 className="banner__div-h1">
              INTRODUCING THE FUTURE OF STRUCTURAL DRYING
            </h1>
            <p className="banner__div-p">
              Pro-Dri Commercial Air movers, Dehumidifiers and Air Scrubbers are
              built to last. Bringing you a return on your investment for years
              to come!
            </p>
          </div>
        </div>
        <div className="banner__div-textColumn">
          <div className="banner__div-iconTextCont">
            <div className="banner__div-iconTextBox" onMouseEnter={() => setHover(!hover)}>
              <div
                className="banner__div-iconCont"
                
              >
                <Tada delay={1500}>
                  <Tada spy={hover}>
                    <img
                      src={barChart}
                      alt="bar chart"
                      className="banner__img-icon"
                    />
                  </Tada>
                </Tada>
              </div>
              <div className="banner__div-h4Cont">
                <h4 className="banner__div-h4">Fast Return on Investment</h4>
              </div>
              <div className="banner__div-textCont">
                <p className="banner__div-textCont-p">
                  We’ve paired lasting quality with aggressive pricing to ensure
                  you enjoy a return on your investment for years to come. Here
                  at Pro-Dri Restoration Equipment we believe a wealthy customer
                  is a happy customer!
                </p>
              </div>
            </div>

            <div className="banner__div-iconTextBox" onMouseEnter={() => setHover1(!hover1)}>
              <div
                className="banner__div-iconCont"
                
              >
                <Tada delay={2000}>
                  <Tada spy={hover1}>
                    <img src={tools} alt="tools" className="banner__img-icon" />
                  </Tada>
                </Tada>
              </div>
              <div className="banner__div-h4Cont">
                <h4 className="banner__div-h4">Lasting Durability</h4>
              </div>
              <div className="banner__div-textCont">
                <p className="banner__div-textCont-p">
                  You work hard for your business, and so does your equipment!
                  You need equipment that will pass the test of time. At Pro-Dri
                  we have designed each of our drying units to perform reliably,
                  hour after hour, job after job and year after year.
                </p>
              </div>
            </div>

            <div className="banner__div-iconTextBox" onMouseEnter={() => setHover2(!hover2)}>
              <div
                className="banner__div-iconCont"
                
              >
                <Tada delay={2500}>
                  <Tada spy={hover2}>
                    <img
                      src={checkMark}
                      alt="checkmark"
                      className="banner__img-icon"
                    />
                  </Tada>
                </Tada>
              </div>
              <div className="banner__div-h4Cont">
                <h4 className="banner__div-h4">Excellent Performance</h4>
              </div>
              <div className="banner__div-textCont">
                <p className="banner__div-textCont-p">
                  Pro-Dri air movers, dehumidifiers and air scrubbers have
                  undergone rigorous testing to ensure they meet or exceed
                  industry standards. You can confidently tackle any job with
                  Pro-Dri Restoration banner.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
