import React, { useState } from "react";
// import Spin from "react-reveal/Spin";
import Fade from "react-reveal/Fade";
import Tada from "react-reveal/Tada";
import CountUp from "react-countup";

import collapse from "../../../../../images/collapse.png";
import collapsed from "../../../../../images/collapsed.png";

export default function ProductsChild({
  name,
  iconUrl,
  airMover,
  airScrubber,
  deHumidifier,
  tagPrefix,
  tagNumbers,
  tagSuffix,
  code,
  imgUrl,
  bannerText,
  description,
  specifications,
}) {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="itemCont">
      <div className="itemTitleCont" onClick={() => setToggle(!toggle)}>
        
          {toggle ? (
            <img src={collapsed} alt="plus sign" className="products__icon collapse" />
          ) : (
            <img src={collapse}  alt="minus sign" className="products__icon collapse" />
          )}
        
        <div className="itemName titleBox">
          <p className="barText">{name}</p>
        </div>
        <div className="itemAirflow titleBox">
          {airMover ? (
            <div className="newStructure">
              {/* <Spin count={3} spy={toggle}> */}
                <img src={iconUrl} className={toggle ? "products__icon-fan" : "products__icon" } />
              {/* </Spin> */}
              <p className="barText">
                {toggle ? (
                  <CountUp
                    end={tagNumbers}
                    prefix={tagPrefix}
                    suffix={tagSuffix}
                    delay={0.25}
                    duration={5}
                    className="hidden"
                  />
                ) : (
                  <span>
                    {tagPrefix}
                    {tagNumbers}
                    {tagSuffix}
                  </span>
                )}
              </p>
            </div>
          ) : null}
          {deHumidifier ? (
            <div className="newStructure">
              <Tada count={2}  spy={toggle}>
                <img src={iconUrl} className="products__icon" />
              </Tada>
              <p className="barText">
                {toggle ? (
                  <CountUp
                    end={tagNumbers}
                    prefix={tagPrefix}
                    suffix={tagSuffix}
                    delay={0.25}
                    duration={5}
                    className="hidden"
                  />
                ) : (
                  <span>
                    {tagPrefix}
                    {tagNumbers}
                    {tagSuffix}
                  </span>
                )}
              </p>
            </div>
          ) : null}
          {airScrubber ? (
            <div className="newStructure">
              {/* <Spin count={3} spy={toggle}> */}
                <img src={iconUrl} className={toggle ? "products__icon-fan" : "products__icon"}/>
              {/* </Spin> */}
              <p className="barText">
                {toggle ? (
                  <CountUp
                    end={tagNumbers}
                    prefix={tagPrefix}
                    suffix={tagSuffix}
                    delay={0.25}
                    decimals={2}
                    duration={5}
                    className="hidden"
                  />
                ) : (
                  <span>
                    {tagPrefix}
                    {tagNumbers}
                    {tagSuffix}
                  </span>
                )}
              </p>
            </div>
          ) : null}
        </div>
        <div className="itemCode titleBox">
          <p className="barText">Xactimate Code: {code}</p>
        </div>
      </div>
      <Fade left collapse when={toggle}>
        <div className="itemDescriptionCont ">
          <div className="itemPhotoCont">
            <img src={imgUrl} alt={name} className="photo" />

            <div className="itemPhotoCaption">
              <p>{bannerText}</p>
            </div>
          </div>
          <div className="itemDescription">
            <p>{description}</p>
          </div>
          <div className="itemSpecs">
            <h5 className="products__h5">Specifications:</h5>
            <ul>
              {specifications.map((specification, i) => (
                <li key={i}>{specification} </li>
              ))}
            </ul>
          </div>
        </div>
      </Fade>
    </div>
  );
}
