import React from "react";
import logo from '../../../images/logo.png'

export default function Footer() {
  const date = new Date().toISOString().substr(0, 4);
  return (
    <div className="footer_section">
      <div className="footer__div-Container">
        <img
          src={logo}
          className="footer__img-logo"
        />

        <div className="footer__div-footerText">
          <p className="footer__p">
            Industry leading equipment for professional restoration specialists.
            Get started today! <br />
            Pro-Dri Copyright © {date}
          </p>
        </div>
      </div>
    </div>
  );
}
