import React, { useState } from "react";
import Nav from "../nav/Nav";
import logo from "../../../images/logo.png";

function Header() {
  const [toggle, setToggle] = useState(false);

  function toggleNav() {
    setToggle(!toggle);
  }

  return (
    <header className={`header ${toggle ? "fixed" : ""}`}>
      <div className="header__container">
        <a href="#home" className="header__logo">
          <img
            src={logo}
            className="header__logo-icon"
            alt="Flood and Fire Solutions logo"
          />
        </a>
        <div
          className={`header__toggle ${toggle ? "open" : ""}`}
          onClick={toggleNav}
        >
          <span className="header__toggle-bars" />
        </div>
      </div>
      <Nav toggle={toggle} toggleNav={toggleNav}></Nav>
    </header>
  );
}

export default Header;
